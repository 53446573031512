import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

export default ( props ) => {

	const { data } = props

	const mouseMove = e => {
		// setting image to active
		const imageId = e.currentTarget.getAttribute( 'data-image-id' )
		const image = document.getElementById( imageId )
		image.classList.add( 'active' )

		// moving the image container div
		const x = e.clientX
		const y = e.clientY;
		const imageContainer = document.getElementById( 'image-container' )
		imageContainer.style.transform = `translate(${ x }px, ${ y }px)`
	}

	const mouseLeave = e => {
		const imageId = e.currentTarget.getAttribute( 'data-image-id' )
		const image = document.getElementById( imageId )
		image.classList.remove( 'active' )
	}

	return (
		<section className="stories-list">
			<div className="stories-list__container wrapper">
				{ data.map( ({ story }) => (
					<div
						className="stories-list__story"
						role="button"
						tabIndex={0}
						key={ story.id }
						data-image-id = { `image-${ story.id }` }
						onMouseMove={ e => mouseMove( e ) }
						onMouseLeave= { e => mouseLeave( e ) }>
						<Link to={`/stories/${ story.slug }/`} className="stories-list__story-title">
							{ story.title }
							<span
								className="stories-list__written-by"
								data-image-id={ `image-${ story.id }` }>By { story.writtenBy }</span>
						</Link>
					</div>
				) ) }
			</div>

			<div className="stories-list__image-wrapper" id="image-container">
				{ data.map( ({ story }) => (
					<div
						className="stories-list__image-container"
						id={ `image-${ story.id }` }
						key={ `image-${ story.id }` }>
						<Img className="stories-list__image" fluid={{ ...story.image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} />
					</div>
				))}
			</div>
		</section>
	)
}